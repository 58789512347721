import React from 'react'
import get from 'lodash/get'
import Seo from '../../components/molecules/seo'
import Layout from '../../components/molecules/layout'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

class Resources extends React.Component {
  render() {
    const title = 'resources'
    const imageMap = get(this.props, 'data.allDatoCmsAsset.edges')

    const iosTextReplacement = imageMap.filter(
      (x: any) => x.node.filename === 'text-replacement-ios.png'
    )[0].node.gatsbyImageData

    const iosShortcut = imageMap.filter(
      (x: any) => x.node.filename === 'ios-shortcut.png'
    )[0].node.gatsbyImageData

    const gifs = imageMap
      .filter((x: any) => x.node.filename.indexOf('.gif') > 0)
      .sort((x: any) => x.node.filename)

    return (
      <Layout>
        <Seo title={title} />
        <section className="hero is-primary">
          <div className="hero-body">
            <h1 className="page-title">{title}</h1>
          </div>
        </section>
        <section className="section resource-page">
          <div className="container">
            <div className="columns">
              <div className="column is-6 is-offset-1">
                <h2>iOS text replacement</h2>
                <div>
                  use text replacement to make it easier to enter todo's or your
                  favorite blocks
                </div>
              </div>
              <div className="column is-4">
                <figure>
                  <GatsbyImage
                    image={iosTextReplacement}
                    alt="iOS text replacement"
                  ></GatsbyImage>
                  <figcaption>todo text replacement on iOS</figcaption>
                </figure>
              </div>
            </div>
            <div className="columns">
              <div className="column is-6 is-offset-1">
                <h2>iOS shortcut</h2>
                <div>
                  import our{' '}
                  <a href="https://www.icloud.com/shortcuts/b0d917b34f2d4491ae7db17fa8e81ff3">
                    iOS shortcut
                  </a>{' '}
                  or create your own using the format to the right.
                </div>
                <div className="nudge">
                  once the shortcut is installed, you can use it
                  <ul>
                    <li>
                      in the <strong>share menu</strong>
                    </li>
                    <li>
                      with <strong>assistive touch</strong>, shown below with a
                      single tap, while{' '}
                      <a href="https://twitter.com/therealchadhall/status/1404166689629958145?s=20">
                        others prefer to back tap
                      </a>
                    </li>
                    <li>
                      from your <strong>home screen</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column is-4">
                <figure>
                  <GatsbyImage
                    image={iosShortcut}
                    alt="phonetonote iOS shortcut"
                  ></GatsbyImage>
                  <figcaption>phonetonote shortcut on iOS</figcaption>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="hero ios-shortcuts-hero resource-hero">
          <div className="hero-body">
            <div className="columns">
              <div className="column is-full">
                <h3>tap or hover to view an iOS shortcut demo</h3>
              </div>
            </div>
            <div className="columns">
              <div className="column is-4">
                <figure>
                  <GatsbyImage
                    image={gifs[1].node.gatsbyImageData}
                    alt="iOS share screen"
                  ></GatsbyImage>
                  <figcaption>
                    phonetonote from the twitter sharescreen
                  </figcaption>
                </figure>
              </div>
              <div className="column is-4">
                <figure>
                  <GatsbyImage
                    image={gifs[2].node.gatsbyImageData}
                    alt="phonetonote from the home screen"
                  ></GatsbyImage>
                  <figcaption>phonetonote from the home screen</figcaption>
                </figure>
              </div>
              <div className="column is-4">
                <figure>
                  <GatsbyImage
                    image={gifs[0].node.gatsbyImageData}
                    alt="phonetonote from assistive touch"
                  ></GatsbyImage>
                  <figcaption>phonetonote from assistive touch</figcaption>
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="section resource-page">
          <div className="container">
            <div className="columns">
              <div className="column is-full">
                <h2>advanced workflows</h2>
                <div>
                  Justin Mather has{' '}
                  <a href="https://roamresearch.com/#/app/itsjustmath-public/page/HvfK1ggAH">
                    documented how he uses phonetonote
                  </a>{' '}
                  to capture fleeting thoughts throughout the day, and roam’s
                  native querying to review them.
                </div>
                <div className="nudge">
                  his demo graph is worth a read, and can inspire you to build
                  your own systems around phonetonote. for more ways to pull
                  data into notes automatically, check out our{' '}
                  <a href="/pages/integrations">integrations</a>.
                </div>
                <div className="nudge">
                  to customize how your synced notes are rendered into your roam
                  graph, you can harness our{' '}
                  <a href="/changelog/roam42-smartblock-integration">
                    roam42 smartblocks integration
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Resources

export const pageQuery = graphql`
  query ResourcePageImages {
    allDatoCmsAsset(filter: { tags: { in: "resource-page-image" } }) {
      edges {
        node {
          id
          gatsbyImageData
          tags
          filename
        }
      }
    }
  }
`
